.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #20232a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-primary {
  background: #373b45 !important;
  border: none !important;
}

.add_document_btn {
  display: flex;
  position: absolute;
  right: 30vw;
  top: 12px;
}

.add_document {
  margin: 7px 2px 7px 10px;
}

.navbar {
  box-shadow: none !important;
  background-color: #373b45;
  color: #ffffff;
}

nav.vertical {
  position: relative;
  height: 92vh;
  overflow: scroll;
}

nav.vertical ul {
  list-style: none;
}

nav.vertical li {
  position: relative;
}

nav.vertical a {
  display: block;
  color: black;
  text-decoration: none;
  padding: 10px 15px;
  transition: 0.2s;
}

nav.vertical li:hover>a {
  background: #778;
}

nav.vertical ul ul {
  background: rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  max-height: 0;
  transition: max-height 1s ease-out;
  overflow: hidden;
}

nav.vertical li:hover>ul {
  max-height: 500px;
  transition: max-height 1s ease-in;
}

.main_document {
  display: flex;
}

.drop_down_left {
  width: 25%;
  background-color: #f7f7f7;
  border-right: 1px solid lightgray;
}

.edit_document_right {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  width: 88%;
  height: 80vh;
  position: relative;
}

.save_button {
  position: absolute;
  bottom: 17px;
  left: 13px;
}

.card-text {
  overflow: auto;
}

.jodit-status-bar {
  visibility: collapse;
}

.row {
  overflow: scroll;
  height: 57vh;
}

.add_module {
  z-index: 2;
}

.btn_permission {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
}

.user_list {
  margin: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.user_card {
  padding: 8px;
  background-color: whitesmoke;
  border-radius: 8px;
  border: 2px solid lightgray;
  height: 193px;
  width: 250px;
  margin-right: 33px;
  margin-top: 23px;
}

.user_card_heading {
  overflow: scroll;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.div {
  padding-left: 20px;
  position: relative;
  border-bottom: 1px solid lightgray;
}

.span {
  list-style: none;
  margin-left: 25px;
  cursor: pointer;
  display: inline-block;
  width: 200px;
  overflow: auto;
  color: #1a1a1a;
}

.li {
  position: relative;
  list-style: none;
  margin-left: 57px;
  margin-top: 9px;
}

.div>h2>span {
  color: #1a1a1a;
  font-size: 17px;
}

.div>form>button {
  font-size: 1rem;
  position: absolute;
  right: 0px;
  z-index: 20;
  top: 11px;
  background: none;
  border: none;
}

.div>button {
  font-size: 1rem;
  position: absolute;
  right: 0px;
  z-index: 20;
  top: 11px;
  background: none;
  border: none;
}

i {
  color: gray;
  margin-right: 10px;
}

i :hover {
  color: black !important;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.navbar-brand :hover {
  color: white !important;
  cursor: pointer !important;
}

.fa-share {
  margin-right: 32px;
}

.user_list_btn {
  display: flex;
  position: absolute;
  right: 19vw;
  top: 11px;
}

.btn-floating>i {
  margin: 2px;
  color: white;
}

.module_name {
  width: 241px;
  overflow: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  cursor: pointer;
}

.delete_submodule {
  border: none;
  background: none;
  position: absolute;
  right: 13%;
  z-index: 20;
}

.add_sub_module {
  display: flex;
}

.add_user {
  display: flex;
}

.dropdown_toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.greeting {
  border-bottom: 2px solid white;
}

.greeting:hover {
  cursor: pointer;
}

.actions {
  font-size: 19px;
}

.edit_title {
  background: none;
  border: none;
  float: right;
}

.edit_title>i:hover {
  transition: 0.5sec ease-in;
  color: black;
  cursor: pointer;
}

.dropdown-button {
  display: flex;
  border: none;
  background: transparent;
  color: white;
}

.add_user_btn {
  color: #1a1a1a;
  background-color: white !important;
}

.add_user_btn:hover {
  color: #1a1a1a;
  background-color: #778;
  transform: 0.3s ease-in;
}

p>button {
  font-size: 1rem;
  position: absolute;
  right: 0px;
  z-index: 20;
  background: none;
  border: none;
}

i:hover {
  color: black;
  transition: 0.3s ease-in;
}

.navbar-brand:hover {
  color: whitesmoke;
  transition: 0.5s ease-in;
  cursor: pointer;
}

.sub_module {
  display: flex;
}

.sub_module_access {
  background: none;
  border: none;
  /* position: absolute;
  right: 66px; */
}

.sub_module_access>.fa-share {
  margin-bottom: 22px;
}

.go_back {
  background: none;
  border: none;
  position: absolute;
  left: 3px;
}

.go_back>i {
  color: white;
  font-size: 1.2©rem;
}